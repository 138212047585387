/* eslint-disable no-useless-escape */
const formatDateType = (format, obj) => {
  const regex = /(\-)|(\/)|(\\)/g;
  const isFoundChar = format.match(regex) ? format.match(regex)[0] : '-';

  return format
    .split(isFoundChar)
    .map(item => {
      const convertToLowerCase = item.toLowerCase();
      return [...`${obj[convertToLowerCase]}`].length === 1 ? `0${obj[convertToLowerCase]}` : obj[convertToLowerCase];
    })
    .join(isFoundChar);
};

function formatAMPM({ hours, minutes }) {
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const getFormatDate = ({
  date,
  hasWeekDay = false,
  hasMonthOnly = false,
  hasTimeOnly = false,
  locale = 'ar-EG',
  formatType,
}) => {
  const newDate = new Date(date);
  let options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  if (hasWeekDay) {
    options = { ...options, weekday: 'long' };
  }

  if (hasMonthOnly) {
    options = { month: 'long' };
  }

  if (hasTimeOnly) {
    options = { hour: '2-digit', minute: '2-digit' };
    const formattingTime = newDate.toLocaleTimeString(locale, options);
    return formattingTime;
  }

  if (locale === 'en-US' && formatType) {
    const time = formatAMPM({ hours: newDate.getHours(), minutes: newDate.getMinutes() });
    const objDate = {
      yyyy: newDate.getFullYear(),
      mm: newDate.getMonth() + 1,
      dd: newDate.getDate(),
    };
    const result = formatDateType(formatType, objDate);
    return `${result} ${time}`;
  }

  const formattingDate = newDate.toLocaleDateString(locale, options);
  return `${formattingDate} `;
};


export const downloadExcelSheet = (responseObject, fileName) => {
  const responseData = responseObject.data;
  const url = window.URL.createObjectURL(new Blob([responseData]), {
    type: responseObject.headers['content-type'],
  });
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    link.remove();
  }, 100);
};


export function isNetworkError(error) {
  return !navigator.onLine || error.message === 'Network Error';
}