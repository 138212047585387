/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import { loadGovernment, loadGovernmentSuccess, loadGovernmentFailed } from './slice';
import { getGovernmentRequest } from 'api/requestCreators/government';
import { showError } from 'redux/utility-sagas';

function* handleLoadGovernmentListRequests() {
  try {
    const {
      data: { data },
    } = yield call(execute, getGovernmentRequest());

    yield put(loadGovernmentSuccess(data));
  } catch (error) {
    yield put(loadGovernmentFailed(error.response?.data));
    yield showError(error);
  }
}

export function* watchGovernmentListRequests() {
  yield takeLatest(loadGovernment.type, handleLoadGovernmentListRequests);
}
