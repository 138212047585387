import { Layout, Space, Dropdown, Avatar, Typography, Image, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import logo from 'assets/Artifact.svg';
const { useToken } = theme;

const { Header } = Layout;
const { Text } = Typography;
const handleButtonClick = () => {
  localStorage.clear();
  window.location.replace('/login');
};

const items = [
  {
    key: '1',
    label: 'log out',
  },
];

const menuProps = {
  items,
  onClick: handleButtonClick,
};

const BaseHeader = () => {
  const getUser = JSON.parse(localStorage.getItem('user'));
  const { token } = useToken();

  return (
    <Header
      className="header"
      style={{ backgroundColor: token.colorPrimary, paddingInline: '16px', color: '#fff'  }}
    >
      <Space
        direction="horizontal"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="logo">
          <Image width={120} src={logo} preview={false} />
        </div>
        <Dropdown menu={menuProps} placement="bottom" trigger={['click']}>
          <div>
            <Avatar icon={<UserOutlined />} />
            <Text style={{ marginInlineStart: '5px', textTransform: 'capitalize', color: '#fff' }}>{getUser?.name ?? ''}</Text>
          </div>
        </Dropdown>
      </Space>
    </Header>
  );
};

export default BaseHeader;
