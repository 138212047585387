import { sellersUrl } from '../utils';
import axios from 'axios';

export const getLoginRequest = user => {
  axios.defaults.headers.common['device'] = user.device;
  return {
    url: `${sellersUrl}auth/login`,
    method: 'POST',
    body: user,
    headers: {
      device: user.device,
    },
  };
};
