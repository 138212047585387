/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  createStatus: 'idle',
  error: null,
  data: null,
  actionStatus: 'idle',
  sellerItem: null,
  totalCount: 10,
  sellerTypes: ['seller', 'driver'],
  downloadLoading: false,
  downloadStatus: false,
};

const sellersListSlice = createSlice({
  name: 'sellersList',
  initialState,
  reducers: {
    loadSellers: state => state,
    loadSellersSuccess: (state, action) => {
      state.data = action.payload?.agents?.map(item => {
        return {
          ...item,
          routes: item?.routes?.map(route => {
            return {
              id: route?.id,
              name: route?.name,
            };
          }),
          city: item?.routes?.map(route => {
            return {
              id: route?.area?.id,
              name: route?.area?.name,
            };
          }),
          salesOffice: item?.routes
            ?.map(route => {
              return {
                id: route?.salesOffice?.id,
                name: route?.salesOffice?.name,
              };
            })
            .filter(item => item.id),
        };
      });
      state.totalCount = action.payload?.totalCount;
      state.isLoading = false;
    },
    loadSellersFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    restCreateStatus: state => {
      state.createStatus = 'idle';
    },

    createSeller: (state, action) => state,
    createSellerSuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
    },
    createSellerFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },
    loadingSellerData: state => state,
    loadSellerDataSuccess: (state, action) => {
      state.sellerItem = {
        ...action.payload,
        routes: action.payload?.route?.map(route => {
          return {
            id: route?.id,
            name: route?.name,
          };
        }),
        city: action.payload?.route?.map(route => {
          return {
            id: route?.area?.id,
            name: route?.area?.name,
          };
        }),
        salesOffice: action.payload?.route
          ?.map(route => {
            return {
              id: route?.salesOffice?.id,
              name: route?.salesOffice?.name,
            };
          })
          .filter(item => item.id),
      };
      state.isLoading = false;
    },
    setItemSeller: (state, action) => {
      state.sellerItem = action.payload;
      state.isLoading = false;
    },
    loadSellerDataFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetSellerState: state => {
      state.sellerItem = initialState.sellerItem;
    },
    resetState: () => {
      return { ...initialState };
    },

    loadingDownloadSellers: state => {
      state.downloadLoading = true;
      return state;
    },
    loadingDownloadSellersSuccess: (state, action) => {
      state.downloadLoading = false;
      state.downloadStatus = true;
      state.error = null;
    },
    loadingDownloadSellersFailed: (state, action) => {
      state.error = action.payload;
      state.downloadLoading = false;
    },

    loadingEditSeller: state => state,
    loadingEditSellerSuccess: (state, action) => {
      state.createStatus = 'success';
      state.error = null;
    },
    loadingEditSellerFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = sellersListSlice;

export const {
  loadSellers,
  loadSellersSuccess,
  loadSellersFailed,
  resetState,
  restCreateStatus,
  createSeller,
  createSellerSuccess,
  createSellerFailed,
  loadingSellerData,
  loadSellerDataSuccess,
  loadSellerDataFailed,
  setItemSeller,
  resetSellerState,

  loadingDownloadSellers,
  loadingDownloadSellersSuccess,
  loadingDownloadSellersFailed,

  loadingEditSeller,
  loadingEditSellerSuccess,
  loadingEditSellerFailed,
} = actions;

export default reducer;
