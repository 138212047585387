import { sellersUrl } from '../utils';

export const getSellersRequest = ({ page, limit }) => {
  return {
    url: `${sellersUrl}admin/agents/?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};

export const addSellersRequest = data => {
  return {
    url: `${sellersUrl}admin/agents`,
    method: 'POST',
    body: data,
  };
};

export const getSellerRequest = id => {
  return {
    url: `${sellersUrl}admin/agents/${id}`,
    method: 'GET',
  };
};

export const getDownloadTemplate = () => {
  return {
    url: `${sellersUrl}admin/agents/template`,
    method: 'GET',
    responseType: 'arraybuffer',
  };
};

export const getEditSellerRequest = (data, id) => {
  return {
    url: `${sellersUrl}admin/agents/${id}`,
    method: 'PUT',
    body: data,
  };
};
