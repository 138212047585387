import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  createStatus: 'idle',
  error: null,
  data: null,
  routeManagementList: null,
  totalCount: 10,
};

const routeSlice = createSlice({
  name: 'routeList',
  initialState,
  reducers: {
    loadRoutes: state => {
      state.isLoading = true;
      return state;
    },
    loadRoutesSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    loadRoutesFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    createRoute: state => {
      state.isLoading = true;
    },
    createRouteSuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      const list = state.data ? state.data : [];
      state.data = [action.payload, ...list].map(item => {
        return {
          ...item,
        };
      });
      state.error = null;
    },
    createRouteFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },

    restRouteCreateStatus: state => {
      state.createStatus = 'idle';
    },

    loadRouteManagement: state => state,
    loadRouteManagementSuccess: (state, action) => {
      state.routeManagementList = action.payload?.routes;
      state.isLoading = false;
      state.totalCount = action.payload?.totalCount;
    },
    loadRouteManagementFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    createSalesOffice: state => {
      state.isLoading = true;
    },
    createSalesOfficeSuccess: state => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
    },
    createSalesOfficeFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },
    restSalesOfficeCreateStatus: state => {
      state.createStatus = 'idle';
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = routeSlice;

export const {
  loadRoutes,
  loadRoutesSuccess,
  loadRoutesFailed,
  createRoute,
  createRouteSuccess,
  createRouteFailed,
  restRouteCreateStatus,

  loadRouteManagement,
  loadRouteManagementSuccess,
  loadRouteManagementFailed,

  createSalesOffice,
  createSalesOfficeSuccess,
  createSalesOfficeFailed,
  restSalesOfficeCreateStatus
  
} = actions;

export default reducer;
