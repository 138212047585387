import { sellersUrl } from '../utils';
import qs from 'qs';

export const getRoutesRequest = ({ page, limit, areaId, ...filters }) => {
  return {
    url: `${sellersUrl}routes`,
    method: 'GET',
    params: {
      filter: { areaId, ...filters },
      limit,
      page
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        skipNulls: true,
      });
    },
  };
};

export const getRoutesManagementRequest = ({ page, limit, areaId, ...filters }) => {
  return {
    url: `${sellersUrl}admin/routes`,
    method: 'GET',
    params: {
      filter: { areaId, ...filters?.filter },
      limit,
      page
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        skipNulls: true,
      });
    },
  };
};

export const getCreateRouteRequest = data => {
  return {
    url: `${sellersUrl}admin/routes`,
    method: 'POST',
    body: data,
  };
};



export const getCreateSalesOfficeRequest = data => {
  return {
    url: `${sellersUrl}admin/sales-office`,
    method: 'POST',
    body: data,
  };
};