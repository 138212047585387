import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';
const SellerPage = lazy(() => import('pages/sellers'));
const SellerDetailsPage = lazy(() => import('pages/sellers/details'));
const RoutesPage = lazy(() => import('pages/routes'));
const AddRoutesPage = lazy(() => import('pages/routes/create-route'));

const customRoutes = [
  {
    path: 'sellers',
    component: <Outlet />,
    roles: [],
    pathName: 'sellers',
    parent: true,
    children: [
      {
        index: true,
        path: 'sellers',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <SellerPage />
          </Suspense>
        ),
        roles: [],
        pathName: 'sellers list',
      },
      {
        path: 'sellers/add-seller',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <SellerDetailsPage />
          </Suspense>
        ),
        pathName: 'add-seller',
        roles: [],
      },
      {
        path: 'sellers/seller-details/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <SellerDetailsPage />
          </Suspense>
        ),
        pathName: 'seller-details',
        roles: [],
      },
      {
        path: 'sellers/seller-edit/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <SellerDetailsPage />
          </Suspense>
        ),
        pathName: 'seller-edit',
        roles: [],
      },
    ],
  },
  {
    path: 'routes',
    component: <Outlet />,
    roles: [],
    pathName: 'routes',
    parent: true,
    children: [
      {
        index: true,
        path: 'routes',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <RoutesPage />
          </Suspense>
        ),
        pathName: 'Routes',
        roles: [],
      },
      {
        path: 'routes/add-route',
        component: (
          <Suspense fallback={<Spin tip="Loading..."/>}>
            <AddRoutesPage />
          </Suspense>
        ),
        pathName: 'add route',
        roles: [],
      }
      // {
      //   path: 'route-details/:id',
      //   component: (
      //     <Suspense fallback={<Spin tip="Loading..." />}>
      //       <RouteDetailsPage />
      //     </Suspense>
      //   ),
      //   pathName: 'route-details',
      //   roles: [],
      // },
      
    ],
  },
];

export { customRoutes };
