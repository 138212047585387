/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  loadSellers,
  loadSellersSuccess,
  loadSellersFailed,
  createSeller,
  createSellerSuccess,
  createSellerFailed,
  loadingSellerData,
  loadSellerDataSuccess,
  loadSellerDataFailed,
  loadingDownloadSellers,
  loadingDownloadSellersSuccess,
  loadingDownloadSellersFailed,
  loadingEditSeller,
  loadingEditSellerSuccess,
  loadingEditSellerFailed,
} from './slice';

import {
  getSellersRequest,
  addSellersRequest,
  getSellerRequest,
  getDownloadTemplate,
  getEditSellerRequest,
} from 'api/requestCreators/sellers';
import { showError } from 'redux/utility-sagas';
import { downloadExcelSheet } from 'utils';

function* handleLoadSellersListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getSellersRequest(filters));

    yield put(loadSellersSuccess(data));
  } catch (error) {
    yield put(loadSellersFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleCreateSellerRequests(action) {
  try {
    const sellerObj = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, addSellersRequest(sellerObj));

    yield put(createSellerSuccess(data));
  } catch (error) {
    yield put(createSellerFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleLoadSellerDataRequests(action) {
  const { payload: id } = action;
  try {
    const {
      data: {
        data: { agent },
      },
    } = yield call(execute, getSellerRequest(id));
    yield put(loadSellerDataSuccess(agent));
  } catch (error) {
    yield put(loadSellerDataFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleDownloadEntitiesRequests() {
  try {
    const response = yield call(execute, getDownloadTemplate());
    downloadExcelSheet(response, 'SellersTemplate.xlsx');
    yield put(loadingDownloadSellersSuccess());
  } catch (error) {
    yield put(loadingDownloadSellersFailed(error.response?.data));
    yield showError(error, 'Error in download, Please try again');
  }
}

function* handleEditSellerRequests(action) {
  const { values, id } = action.payload;
  try {
    const item = {
      ...values,
    };

    const {
      data: { data },
    } = yield call(execute, getEditSellerRequest(item, id));

    yield put(loadingEditSellerSuccess(data));
  } catch (error) {
    yield put(loadingEditSellerFailed(error.response?.data));
    yield showError(error);
  }
}

export function* watchSellersListPageRequests() {
  yield takeLatest(loadSellers.type, handleLoadSellersListRequests);
  yield takeLatest(createSeller.type, handleCreateSellerRequests);
  yield takeLatest(loadingSellerData.type, handleLoadSellerDataRequests);
  yield takeLatest(loadingDownloadSellers.type, handleDownloadEntitiesRequests);
  yield takeLatest(loadingEditSeller.type, handleEditSellerRequests);
}
