/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  loadRoutes,
  loadRoutesSuccess,
  loadRoutesFailed,
  createRoute,
  createRouteSuccess,
  createRouteFailed,
  loadRouteManagement,
  loadRouteManagementSuccess,
  loadRouteManagementFailed,
  createSalesOffice,
  createSalesOfficeSuccess,
  createSalesOfficeFailed,
} from './slice';

import {
  getRoutesRequest,
  getCreateRouteRequest,
  getRoutesManagementRequest,
  getCreateSalesOfficeRequest,
} from 'api/requestCreators/routes';
import { showError } from 'redux/utility-sagas';

import { showAlert } from 'redux/slices/uiSlice';

function* handleLoadRouteListRequests(action) {
  try {
    const filters = { ...action?.payload };

    const {
      data: { data },
    } = yield call(execute, getRoutesRequest(filters));

    yield put(loadRoutesSuccess(data));
  } catch (error) {
    yield put(loadRoutesFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleLoadRouteManagementListRequests(action) {
  try {
    const filters = { ...action?.payload };

    const {
      data: { data },
    } = yield call(execute, getRoutesManagementRequest(filters));

    yield put(loadRouteManagementSuccess(data));
  } catch (error) {
    yield put(loadRouteManagementFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleCreateRouteRequests(action) {
  try {
    const item = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, getCreateRouteRequest(item));

    yield put(createRouteSuccess(data));
  } catch (error) {
    yield put(createRouteFailed(error.response?.data));
    yield showError(error);
  }
}

function* handleCreateSalesOfficeRequests(action) {
  try {
    const item = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, getCreateSalesOfficeRequest(item));

    yield put(createSalesOfficeSuccess(data));
  } catch (error) {
    yield put(createSalesOfficeFailed(error.response?.data));
    yield showError(error);
  }
}

export function* watchRouteListRequests() {
  yield takeLatest(loadRoutes.type, handleLoadRouteListRequests);
  yield takeLatest(createRoute.type, handleCreateRouteRequests);
  yield takeLatest(loadRouteManagement.type, handleLoadRouteManagementListRequests);
  yield takeLatest(createSalesOffice.type, handleCreateSalesOfficeRequests);
}
