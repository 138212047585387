import UIReducer from './slices/uiSlice';
import appReducer from './slices/appSlice';
import loginReducer from 'pages/login/store/slice';
import routesReducer from './slices/routeSlice/slice';
import governmentReducer from './slices/government/slice';
import sellersReducer from "pages/sellers/store/slice"

const createRootReducer = () => {
  return {
    UIReducer,
    appReducer,
    loginReducer,
    routesReducer,
    governmentReducer,
    sellersReducer
  };
};

export default createRootReducer;
