import { useState } from 'react';
import {  UsergroupAddOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem(
    <NavLink to="/">
      <span>Sellers</span>
    </NavLink>,
    'sellers',
    <UsergroupAddOutlined />
  ),
  getItem(
    <NavLink to="/routes">
      <span>Routes</span>
    </NavLink>,
    'routes',
    <UsergroupAddOutlined />
  ),

];
const BaseSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  return (
    <Sider
      width={200}
      className="site-layout-background"
      collapsible
      collapsed={collapsed}
      trigger={null}
      onCollapse={value => setCollapsed(value)}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[pathSnippets[0] ?? 'sellers']}
        defaultOpenKeys={[pathSnippets[0] ?? 'sellers']}
        style={{
          height: '100%',
          borderRight: 0,
          paddingTop: '10px',
        }}
        items={items}
      />
    </Sider>
  );
};

export default BaseSidebar;
